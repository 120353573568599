import * as Yup from 'yup'

export const GRID_TYPES_MAP = {
  '24-2': '24-2',
  '10-2': '10-2',
  '30-2': '30-2',
  '24-2C': '24-2C',
}
export const GRID_TYPES = [
  GRID_TYPES_MAP['24-2'],
  GRID_TYPES_MAP['10-2'],
  GRID_TYPES_MAP['30-2'],
  GRID_TYPES_MAP['24-2C'],
]

export const RESPONSE_TYPES = [
  'clicker',
  // 'seccade central'
]


export const STRATEGIES_LABELS = {
  zest: {
    label: 'ZEST',
    value: 'zest',
  },
  'zest fast': {
    label: 'ZEST Fast',
    value: 'zest fast',
  },
  'zest faster': {
    label: 'ZEST Faster',
    value: 'zest faster',
  },
  'full threshold': {
    label: 'Full Threshold',
    value: 'full threshold',
  },
  'aize': {
    label: 'AIZE',
    value: 'aize',
  }
};

export const STRATEGIES = [
  STRATEGIES_LABELS.zest,
  STRATEGIES_LABELS['zest faster'],
  STRATEGIES_LABELS['zest fast'],
  STRATEGIES_LABELS['full threshold'],
  STRATEGIES_LABELS['aize'],
]

export const EYE = [
  'right',
  'left',
  // 'binoculor',
  // 'left then right',
  // 'right then left'
]

export const EYE_ENUM = {
  LEFT: 'left',
  RIGHT: 'right',
  BINOCULOR: 'binoculor',
  LEFT_THEN_RIGHT: 'left then right',
  RIGHT_THEN_LEFT: 'right then left'
}

export const TEST_OTHER_EYE_OPTIONS = [
  'left then right',
  'right then left'
]

export const TEST_FOVEAL_THRESHOLD = [
  'no',
  'yes',
]

export const SELECTED_CYLINDER_FORMAT = [
  '+',
  '-'
]

export const LANGUAGES = [
  'english',
  'french'
]

export const AssessmentConfigurationForm = {
  patientId: "",
  hmdId: "",
  configuration: {
    grid: "",
    responseType: RESPONSE_TYPES[0] || "",
    strategy: STRATEGIES[0] || "",
    eye: "",
    testFovealThreshold: "",
    selectedCylinderFormat: SELECTED_CYLINDER_FORMAT[0] || "",
    // patientParameters: "",
    visualAcuity: "",
    visualAcuityPart1: "",
    visualAcuityPart2: "",
    osSphere: "",
    odSphere: "",
    osCylinder: "",
    odCylinder: "",
    osAxis: "",
    odAxis: "",
    os: "",
    od: "",
    vertexDistance: 13.5,
    default: false,
  }
}

export const AssessmentConfigurationSchema = () => {
  let assessmentConfigurationSchema = {
    patientId: Yup.string().required('Patient Id is required'),
    hmdId: Yup.string().required('HMD Id is required')
  }
  let configurationSchema = {
    grid: Yup.string()
      .required('Grid is required')
      .oneOf(GRID_TYPES),
    responseType: Yup.string()
      .required('Response type is required')
      .oneOf(RESPONSE_TYPES),
    strategy: Yup.string()
      .required('Strategy is required')
      .oneOf(STRATEGIES.map(strategy => strategy.value), 'Invalid strategy'),
    eye: Yup.string()
      .required('Eye is required')
      .oneOf(EYE),
    testFovealThreshold: Yup.string()
      .required('Test foveal threshold is required')
      .oneOf(TEST_FOVEAL_THRESHOLD),
    // patientParameters: Yup.number()
    //   .required('Patient parameters is required'),
    osSphere: Yup.number()
      .required('Required')
      .min(-30, "Must be more than -30")
      .max(30, "Must be less than 30"),
    odSphere: Yup.number()
      .required('Required')
      .min(-30, "Must be more than -30")
      .max(30, "Must be less than 30"),
    // visualAcuity: Yup.number()
    //   .required('Required')
    //   .min(0, "Must be more than 0")
    //   .max(30, "Must be less than 30"),
    visualAcuityPart1: Yup.number()
      .required('Required')
      .min(0, "Must be more than 0")
      .max(30, "Must be less than 30"),
    visualAcuityPart2: Yup.number()
      .required('Required')
      .min(0, "Must be more than 0")
      .max(30, "Must be less than 30"),
    osCylinder: Yup.number()
      .required('Required')
      .min(-30, "Must be more than -30")
      .max(30, "Must be less than 30"),
    odCylinder: Yup.number()
      .required('Required')
      .min(-30, "Must be more than -30")
      .max(30, "Must be less than 30"),
    osAxis: Yup.number()
      .required('Required')
      .min(0, "Must be more than -30")
      .max(180, "Must be less than 30"),
    odAxis: Yup.number()
      .required('Required')
      .min(0, "Must be more than -30")
      .max(180, "Must be less than 30"),
    trialLensOSsphere: Yup.number(),
    trialLensOScylinder: Yup.number(),
    trialLensOSaxis: Yup.number(),
    trialLensODsphere: Yup.number(),
    trialLensODcylinder: Yup.number(),
    trialLensODaxis: Yup.number(),
    vertexDistance: Yup.number(),
    default: Yup.boolean()
      .required('Default is required')
  }
  
  assessmentConfigurationSchema.configuration = Yup.object(configurationSchema)
  return Yup.object().shape(assessmentConfigurationSchema)
}

export const AssessmentCalibrationForm = {
  calibration: {
    language: "english", /* Temporal till V100, will be removed initial value at V200 */
    volume: 0,
    calibrationCheck: false,
    patientGazeEyeCheck: false,
    positionCheck: false
  }
}

export const AssessmentCalibrationSchema = () => {
  let assessmentCalibrationSchema = {}

  let calibrationSchema = {
    language: Yup.string()
      .required('Language is required')
      .oneOf(LANGUAGES),
    volume: Yup.number()
      .required('Volume is required'),
    calibrationCheck: Yup.boolean()
      .required('Calibration check is required'),
    patientGazeEyeCheck: Yup.boolean()
      .required('Patient gaze eye check is required'),
    positionCheck: Yup.boolean()
      .required('Position check is required')
  }

  assessmentCalibrationSchema.calibration = Yup.object(calibrationSchema)
  return Yup.object().shape(assessmentCalibrationSchema)
}
